import { AxiosPromise } from 'axios';

import { client } from './client';

export class taskApi {
  static create(formData: FormData): AxiosPromise {
    return client
      .post('/tasks', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((err) => Promise.reject(err.response));
  }

  static getResults(taskId: string): AxiosPromise {
    return client
      .get(`/tasks/${taskId}`)
      .catch((err) => Promise.reject(err.response));
  }
}
